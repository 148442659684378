import React, { useState, useRef, useEffect } from 'react';
import { db, auth } from '../firebase';
import { collection, setDoc, getDocs, doc, where, query, toObject } from 'firebase/firestore';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { Alert, Form, Button, Card, Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';
import { createLibrary } from '../services/cards';

export default function Register() {
    const { userObject } = useAuthContext();
    const { cards } = useAuthContext();
    const usernameRef = useRef();
    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmationRef = useRef();
    const [usernameList, setUsernameList] = useState();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const usersCollection = collection(db, 'users');
    const libraryCollection = collection(db, 'card-libraries');

    const getUsernameList = async () => {
        //this does not work yet - needs to get specific usernames
        //probably should only pull the correct data from the database for security reasons
        const snapshot = await getDocs(usersCollection);
        let usernameData = [];
        snapshot.forEach(username => {
            usernameData.push({
                ...username.data()
            });
        });
        usernameData = usernameData[0];
        setUsernameList(usernameData.username);
    }

    async function handleSubmit(e) {
        e.preventDefault();

        if (passwordRef.current.value !== passwordConfirmationRef.current.value) {
            return setError('Passwords do not match');
        }

        //if (usernameList != null && usernameRef.current.value === usernameList) {
        //    return setError('Username already exists');
        //}

        try {
            setError('');
            setLoading(true);
            await createUserWithEmailAndPassword(auth, emailRef.current.value, passwordRef.current.value).then(credential => {
                if (credential && credential.user) {
                    setDoc(doc(usersCollection, credential.user.uid.toString()), {
                        username: usernameRef.current.value,
                        email: emailRef.current.value,
                        admin: false
                    });
                }
            });
            navigate('/');
        } catch {
            return setError('Error creating account');
        }

        setLoading(false);
    }

    useEffect(() => {
        //getUsernameList();
    }, []);

    return (
        <>
            <Container className='d-flex align-items-center justify-content-center' style={{ minHeight: '100vh' }}>
                <div className='w-100' style={{ maxWidth: '400px' }}>
                    <Card>
                        <Card.Body>
                            <h2 className='text-center mb-4'>Create an Account</h2>
                            {error && <Alert variant='danger'>{error}</Alert>}
                            <Form onSubmit={handleSubmit}>
                                <Form.Group id='username'>
                                    <Form.Label>Username</Form.Label>
                                    <Form.Control type='text' ref={usernameRef} required />
                                </Form.Group>
                                <Form.Group id='email'>
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control type='email' ref={emailRef} required />
                                </Form.Group>
                                <Form.Group className='mt-2' id='password'>
                                    <Form.Label>Choose a Password</Form.Label>
                                    <Form.Control type='password' ref={passwordRef} required />
                                </Form.Group>
                                <Form.Group className='mt-2'>
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Form.Control type='password' ref={passwordConfirmationRef} required />
                                </Form.Group>
                                <Button disabled={loading} className='w-100 mt-4' type='submit'>Sign Up</Button>
                            </Form>
                        </Card.Body>
                    </Card>
                    <div className='w-100 text-center mt-2'>
                        Already have an account? <Link to='/login'>Log In</Link>
                    </div>
                </div>
            </Container>
        </>
    )
}