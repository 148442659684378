import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';
import { lpad } from '../services/cards';
import { Grid, Typography, TextField, MenuItem, Paper, Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Divider from '@mui/material/Divider';
import './LorcanaCard.css';

export default function LorcanaCard() {
    const { id } = useParams();
    const { cards } = useAuthContext();
    const { userObject } = useAuthContext();
    const navigate = useNavigate();
    const [showForm, setShowForm] = useState(false);

    const nameRef = useRef();
    const subnameRef = useRef();
    const typeRef = useRef();
    const costRef = useRef();
    const inkRef = useRef();
    const inkableRef = useRef();
    const loreRef = useRef();
    const strengthRef = useRef();
    const willpowerRef = useRef();
    const movecostRef = useRef();
    const rarityRef = useRef();

    let card = (cards.filter(card => card.id === id))[0];

    let goPrev = () => {
        navigate('/lorcana/' + lpad((Number(card.id) - 1), 5))
    }

    let goNext = () => {
        navigate('/lorcana/' + lpad((Number(card.id) + 1), 5))
    }

    let handleEdit = (e) => {
        e.preventDefault();
        card.update(
            card.id,
            card.chapter,
            card.index,
            costRef.current.value,
            nameRef.current.value,
            subnameRef.current.value,
            rarityRef.current.value,
            inkRef.current.value,
            inkableRef.current.value,
            typeRef.current.value,
            strengthRef.current.value,
            willpowerRef.current.value,
            loreRef.current.value,
            movecostRef.current.value
        );
        setShowForm(false);
    }

    const theme = createTheme({
        typography: {
            fontFamily: 'Roboto Condensed',
            fontSize: 22,
        },
    })

    return (
        <>
        {card ?
        <ThemeProvider theme={theme}>
            <Grid container my={5}>
                <Grid container justifyContent='right' xs={12} md={5} sx={{ padding: '3%' }}>
                    <img src={card.imgUrl} className={card.type === 'location' ? 'locationCard lorcanaCard' : 'lorcanaCard'} alt='Card art.' />
                </Grid>
                <Grid item xs={12} md={7} sx={{ padding: '3%' }}>
                    <div className={card.ink + 'CardName'}>
                        <Typography variant='h3'>{card.name.toUpperCase()}</Typography>
                        <Typography variant='h6'>{card.subname ? card.subname : null}</Typography>
                    </div>
                    <Divider variant='middle' sx={{ bgcolor: 'white' }} />
                    <Typography><FontAwesomeIcon icon='fa-solid fa-star-half-stroke' /> Type: {card.type}</Typography>
                    <Divider variant='middle' sx={{ bgcolor: 'white' }} />
                    <Typography><FontAwesomeIcon icon='fa-solid fa-star-half-stroke' /> Rarity: {card.rarity}</Typography>
                    <Divider variant='middle' sx={{ bgcolor: 'white' }} />
                    <Typography><FontAwesomeIcon icon='fa-solid fa-arrow-down' /> Cost: {card.cost}</Typography>
                    <Divider variant='middle' sx={{ bgcolor: 'white' }} />
                    <Typography>Inkable: {card.inkable.toString()}</Typography>
                    {card.type === 'character' | card.type === 'location' ? 
                        <>
                            <Divider variant='middle' sx={{ bgcolor: 'white' }} />
                            <Typography>Lore: {card.lore}</Typography>
                        </>
                    : null}
                    {card.type === 'character' ?
                        <>
                            <Divider variant='middle' sx={{ bgcolor: 'white' }} />
                            <Typography><FontAwesomeIcon icon='fa-solid fa-gun' /> Strength: {card.strength}</Typography>
                        </>
                    : null }
                    {card.type === 'character' | card.type === 'location' ?
                        <>
                            <Divider variant='middle' sx={{ bgcolor: 'white' }} />
                            <Typography><FontAwesomeIcon icon='fa-solid fa-shield-halved' /> Willpower: {card.willpower}</Typography>
                        </>
                    : null}
                    {card.type === 'location' ?
                        <>
                            <Divider variant='middle' sx={{ bgcolor: 'white' }} />
                            <Typography><FontAwesomeIcon icon='fa-solid fa-shield-halved' /> Move cost: {card.movecost}</Typography>
                        </>
                    : null}
                    <Divider variant='middle' sx={{ bgcolor: 'white' }} />
                </Grid>
                <Grid container justifyContent='center' xs={12}>
                    <Button onClick={()=>{goPrev();}}>Previous</Button>
                    {userObject.admin === true ? 
                        <Button onClick={()=>{setShowForm(true);}}>Edit</Button>
                    : null}
                    <Button onClick={()=>{goNext();}}>Next</Button>
                </Grid>
            </Grid>
            {showForm ?
            <Paper>
                <form onSubmit={handleEdit}>
                    <TextField label='Name' inputRef={nameRef} type='text' defaultValue={card.name} />
                    <TextField label='Subname' inputRef={subnameRef} type='text' defaultValue={card.subname} />
                    <TextField label='Type' inputRef={typeRef} type='text' defaultValue={card.type} />
                    <TextField label='Rarity' inputRef={rarityRef} type='text' defaultValue={card.rarity} />
                    <TextField label='Cost' inputRef={costRef} type='text' defaultValue={card.cost} />
                    <TextField label='Ink' inputRef={inkRef} type='text' defaultValue={card.ink} />
                    <TextField label='Inkable' inputRef={inkableRef} select defaultValue={card.inkable}> 
                        <MenuItem value={true}>True</MenuItem>
                        <MenuItem value={false}>False</MenuItem>
                    </TextField>
                    <TextField label='Lore' inputRef={loreRef} type='text' defaultValue={card.lore} />
                    <TextField label='Strength' inputRef={strengthRef} type='text' defaultValue={card.strength} />
                    <TextField label='Willpower' inputRef={willpowerRef} type='text' defaultValue={card.willpower} />
                    <TextField label='Move cost' inputRef={movecostRef} type='text' defaultValue={card.movecost} />
                    <Button type='submit'>Submit</Button>
                    <Button onClick={()=>{setShowForm(false);}}>Cancel</Button>
                </form>
            </Paper>
            : null}
        </ThemeProvider>
        : <p>No card found.</p> }
        </>
    )
}