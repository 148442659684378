import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDgV8QsmRxP4RrcAZQTb3WXAc6LLEgk1mQ",
  authDomain: "lorcana-tracker-8101a.firebaseapp.com",
  projectId: "lorcana-tracker-8101a",
  storageBucket: "lorcana-tracker-8101a.appspot.com",
  messagingSenderId: "958951831600",
  appId: "1:958951831600:web:2709a460d13d163b1715f5",
  measurementId: "G-X7P19RX80C"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);
export default app;

