import { db } from '../firebase';
import { getDoc, doc } from 'firebase/firestore';

function User(uid, username, email, admin) {
    this.uid = uid;
    this.username = username;
    this.email = email;
    this.admin = admin;
}

export const getUserObject = async (userUid) => {

    if (userUid) {
        const userDoc = doc(db, 'users', userUid);
        const snapshot = await getDoc(userDoc);

        if (snapshot.exists()) {
            return new User(userUid, snapshot.data().username, snapshot.data().email, snapshot.data().admin);
        } else {
            console.log('No document found');
        }
    } else {
        return null;
    }
}