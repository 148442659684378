import React, { useContext, useState, useEffect } from 'react';
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { getUserObject } from '../services/users';
import { fetchCards, fetchIcons } from '../services/cards';

const AuthContext = React.createContext();

export function useAuthContext() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(true);
    const [userObject, setUserObject] = useState();
    const [cards, setCards] = useState([]);
    const [icons, setIcons] = useState();

    useEffect(() => {
        const requestCards = async (user) => {
            const cardsResponse = await fetchCards(user ? user.uid.toString() : 'fKhUkmrmctX8fWicpnxHoK6DWaa2');
            setCards(cardsResponse);
            const iconsResponse = await fetchIcons();
            console.log(iconsResponse)
            setIcons(iconsResponse);
        }

        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user);
            if (user) {
                getUserObject(user.uid).then(response => { setUserObject(response); });
                requestCards(user);
                
            } else {
                setUserObject(null);
                requestCards();
            }
            setLoading(false);
        })

        return unsubscribe;
    }, [])

    const value = {
        currentUser,
        userObject,
        cards,
        icons
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}