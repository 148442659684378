import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';

export function PrivateRoute({ children }) {
    const { currentUser } = useAuthContext();

    return currentUser ? children : <Navigate to='/login' />;
}

export function AdminRoute({ children }) {
    const { userObject } = useAuthContext();

    return userObject.admin === 'true' ? children : <Navigate to='/' />;
}