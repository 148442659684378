import React, { useState, useRef } from 'react'
import { login } from './auth';
import { Alert, Form, Button, Card, Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

export default function Login() {
    const emailRef = useRef();
    const passwordRef = useRef();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    async function handleSubmit(e) {
        e.preventDefault();

        try {
            setError('');
            setLoading(true);
            await login(emailRef.current.value, passwordRef.current.value);
            navigate('/explore');
        } catch {
            setError('Failed to sign in');
        }

        setLoading(false);
    }

    return (
        <>
            <Container className='d-flex align-items-center justify-content-center' style={{ minHeight: '100vh' }}>
                <div className='w-100' style={{ maxWidth: '400px' }}>
                    <Card>
                        <Card.Body>
                            <h2 className='text-center mb-4'>Log In</h2>
                            {error && <Alert variant='danger'>{error}</Alert>}
                            <Form onSubmit={handleSubmit}>
                                <Form.Group id='email'>
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control type='email' ref={emailRef} required />
                                </Form.Group>
                                <Form.Group className='mt-2' id='password'>
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type='password' ref={passwordRef} required />
                                </Form.Group>
                                <Button disabled={loading} className='w-100 mt-4' type='submit'>Log In</Button>
                            </Form>
                            <div className='w-100 text-center mt-3'>
                            <Link to='/passwordreset'>Forgot Password?</Link>
                            </div>
                        </Card.Body>
                    </Card>
                    <div className='w-100 text-center mt-2'>
                        Need an account? <Link to='/register'>Sign Up</Link>
                    </div>
                </div>
            </Container>
        </>
    )
}