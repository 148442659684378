import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Typography, Grid } from '@mui/material';
import { useAuthContext } from '../contexts/AuthContext';
import './LibraryItem.css';

export default function LibraryItem(properties) {
    const { card } = properties;
    const { reconcileLibrary } = properties;
    const { userObject } = useAuthContext();
    const { icons } = useAuthContext();
    const [loading, setLoading] = useState(true);
    const [standard, setStandard] = useState();
    const [foil, setFoil] = useState();
    const [lastSelected, setLastSelected] = useState();

    const [rarityIcon, setRarityIcon] = useState();

    const theme = createTheme({
        typography: {
            fontFamily: 'Roboto Condensed',
            overflow: 'hidden'
        },
    })

    const standardMinusHandler = (key) => {
        let lastClicked = document.getElementsByClassName('lastClicked')[0];

        if(lastClicked && lastSelected !== key) {
            lastClicked.classList.remove('lastClicked');
        }

        setLastSelected(key);
        setStandard(standard - 1);
        card.library.removeStandard();
        reconcileLibrary();
    }

    const standardPlusHandler = (key) => {
        let lastClicked = document.getElementsByClassName('lastClicked')[0];

        if(lastClicked && lastSelected !== key) {
            lastClicked.classList.remove('lastClicked');
        }

        setLastSelected(key);
        setStandard(standard + 1);
        card.library.addStandard();
        reconcileLibrary();
    };

    const foilMinusHandler = (key) => {
        let lastClicked = document.getElementsByClassName('lastClicked')[0];

        if(lastClicked && lastSelected !== key) {
            lastClicked.classList.remove('lastClicked');
        }

        setLastSelected(key);
        setFoil(foil - 1);
        card.library.removeFoil();
        reconcileLibrary();
    }

    const foilPlusHandler = (key) => {
        let lastClicked = document.getElementsByClassName('lastClicked')[0];

        if(lastClicked && lastSelected !== key) {
            lastClicked.classList.remove('lastClicked');
        }

        setLastSelected(key);
        setFoil(foil + 1);
        card.library.addFoil();
        reconcileLibrary();
    }

    useEffect(() => {
        const iconGetter = async () => {
            switch (card.rarity) {
                case 'common':
                    setRarityIcon(icons.common);
                    break;
                case 'uncommon':
                    setRarityIcon(icons.uncommon);
                    break;
                case 'rare':
                    setRarityIcon(icons.rare);
                    break;
                case 'super rare':
                    setRarityIcon(icons.superRare);
                    break;
                case 'legendary':
                    setRarityIcon(icons.legendary);
                    break;
                case 'enchanted':
                    setRarityIcon(icons.enchanted);
                    break;
                case 'promo':
                    setRarityIcon(icons.promo);
                    break;
                default:
                    break;
            }
        }
        iconGetter();
        
        setStandard(card.library.standard);
        setFoil(card.library.foil);
        setLoading(false);
    }, []);

    return (
        <>
            {!loading ? 
                <ThemeProvider theme={theme}>
                    <Grid item xs={12} md={4} lg={3}>
                        <Grid container key={card.id} className={card.ink + 'Library libraryItem'} >
                            {card.rarity !== 'enchanted' && card.rarity!== 'promo' ?
                                <Grid item className='standardControl' xs={3}>
                                    <button onClick={()=>{standardMinusHandler(card.id + 'stdmin')}}><FontAwesomeIcon icon='fa-solid fa-minus' className={(card.id + 'stdmin') === lastSelected ? 'lastClicked' : ''} /></button>
                                    <Typography className='quantity'>{standard}</Typography>
                                    <button onClick={()=>{standardPlusHandler(card.id + 'stdpls')}}><FontAwesomeIcon icon='fa-solid fa-plus' className={(card.id + 'stdpls') === lastSelected ? 'lastClicked' : ''} /></button>
                                </Grid>
                            : null}
                            <Grid item className='cardDetails' xs={card.rarity !== 'enchanted' && card.rarity!== 'promo' ? 6 : 9}>
                                <Typography noWrap>
                                    <img src={rarityIcon} alt={card.rarity} className='rarityIcon' />
                                    {card.index}. {card.name} {card.subname ? '- ' : null} {card.subname}
                                </Typography>
                            </Grid>
                            <Grid item className='foilControl' xs={3}>
                                <button onClick={()=>{foilMinusHandler(card.id + 'flmin')}}><FontAwesomeIcon icon='fa-solid fa-minus' className={(card.id + 'flmin') === lastSelected ? 'lastClicked' : ''} /></button>
                                <Typography className='quantity'>{foil}</Typography>
                                <button onClick={()=>{foilPlusHandler(card.id + 'flpls')}}><FontAwesomeIcon icon='fa-solid fa-plus' className={(card.id + 'flpls') === lastSelected ? 'lastClicked' : ''} /></button>
                            </Grid>
                        </Grid>
                    </Grid>
                </ThemeProvider>
            : null}
        </>
    )
}