import React, { useState, useEffect, useRef } from 'react';
import { Grid, Typography, Container } from '@mui/material';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import { useAuthContext } from '../../contexts/AuthContext';
import { updateLibrary } from '../../services/cards';
import { LibraryItem, CardFilter } from '../';

export default function Library() {
    const [loading, setLoading] = useState(true);
    const [icons, setIcons] = useState();
    const [maxIndex, setMaxIndex] = useState(100);
    const { cards } = useAuthContext();
    const { userObject } = useAuthContext();
    const [filteredCards, setFilteredCards] = useState();
    const bottomRef = useRef(null);

    const reconcileLibrary = () => {
        const libraryToReconcile = [];
        cards.forEach(card => {
            libraryToReconcile.push(card.library);
        });
        const jsonLibrary = JSON.stringify(libraryToReconcile);
        updateLibrary(jsonLibrary,userObject.uid.toString());
    }

    useEffect(() => {
        setFilteredCards(cards);
        setLoading(false);

        const storedMaxIndex = sessionStorage.getItem('libraryMaxIndex');

        if (storedMaxIndex) {
            setMaxIndex(parseInt(storedMaxIndex));
        }

        const insertNewCards = () => {
            if (maxIndex < cards.length + 1) {
                const newIndex = maxIndex + 100;
                setMaxIndex(newIndex);
                sessionStorage.setItem('libraryMaxIndex', newIndex);
            }
        }

        const cardsObserver = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.intersectionRatio > 0 && entry.intersectionRatio < 1) {
                    insertNewCards();
                }
            });
        }, {
            rootMargin: '0px 0px 0px 0px',
            threshold: 0
        });

        cardsObserver.observe(bottomRef.current);
        return () => {
            cardsObserver.disconnect(bottomRef.current);
        }
    }, [maxIndex]);

    return (
        <>
            <Grid container className='bigMargin'>
                <Grid item xs={12}>
                    <Typography variant='h3' className='text-center'>Your Card Library</Typography>
                </Grid>
                <Grid item m={3} xs={12}>
                    <CardFilter cards={cards} setFilteredCards={setFilteredCards} />
                </Grid>
                {!loading ? 
                    <Grid container>
                        {filteredCards.filter((card, index) => index < maxIndex).map(card => (<LibraryItem key={card.id} card={card} reconcileLibrary={reconcileLibrary} icons={icons} />))}
                    </Grid>
                : null}
                <div ref={bottomRef}>End of Cards</div>
            </Grid>
        </>
    )
}