import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './CardItem.css';

export default function CardItem(properties) {
    const { card } = properties;
    const navigate = useNavigate();

    const handleOpen = () => {
        sessionStorage.setItem('clickedCard', card.id);
        navigate('/lorcana/' + card.id);
    };

    useEffect(() => {
    }, []);

    return (
        <>
            <Grid item xs={6} md={2} m={1.5}>
                <a onClick={handleOpen}><img src={card.imgUrl} alt={(card.name + ' ' + card.subname)} className={(card.rarity === 'enchanted' ? 'enchanted ' : '') + (card.type === 'location' ? 'location ' : '') + card.ink + ' tradingCard img-fluid'}></img></a>
            </Grid>
        </>
    )
}