import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, AppBar, Toolbar, Button, Container, Divider, Typography, MenuItem, Drawer} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useAuthContext } from '../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Navbar() {
    const { userObject } = useAuthContext();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    }

    return (
        <>
        <div>
            <AppBar
                position='fixed'
                sx={{
                    boxShadow: 0,
                    bgcolor: 'transparent',
                    backgroundImage: 'none',
                    mt: 2,
                }}
            >
                <Container maxWidth='lg'>
                    <Toolbar
                        variant='regular'
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            flexShrink: 0,
                            borderRadius: '999px',
                            bgcolor: 'rgba(255, 255, 255, 0.4)',
                            backdropFilter: 'blur(24px)',
                            maxHeight: 40,
                            border: '1px solid',
                            broderColor: 'divider',
                            boxShadow: '0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)',
                        }}
                    >
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: 'flex',
                                alignItems: 'center',
                                ml: '-18px',
                                px: 0,
                            }}
                        >
                            <MenuItem
                                    onClick={() => navigate('/')}
                                    sx={{ py: '6px', px: '12px' }}
                                >
                                    <Typography>
                                        Traders Trove
                                    </Typography>
                                </MenuItem>
                        </Box>
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: 'flex',
                                alignItems: 'center',
                                ml: '-18px',
                                px: 0,
                            }}
                        >
                            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                                <MenuItem
                                    onClick={() => navigate('/explore')}
                                    sx={{ py: '6px', px: '12px' }}
                                >
                                    <Typography variant='body2' color='text.primary'>
                                        Explore
                                    </Typography>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => navigate('/library')}
                                    sx={{ py: '6px', px: '12px' }}
                                >
                                    <Typography variant='body2' color='text.primary'>
                                        Library
                                    </Typography>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => navigate('/decks')}
                                    sx={{ py: '6px', px: '12px' }}
                                >
                                    <Typography variant='body2' color='text.primary'>
                                        Decks
                                    </Typography>
                                </MenuItem>
                            </Box>
                        </Box>
                        <Box
                            sx={{ 
                                display: { xs: 'none', md: 'flex' },
                                gap: 0.5,
                                alignItems: 'center',
                            }}
                        >
                            {userObject ? 
                                <MenuItem onClick={() => navigate('/account')}>
                                    <FontAwesomeIcon icon='fa-regular fa-circle-user' />
                                    {userObject.username}
                                </MenuItem>
                            :
                            <>
                                <Button
                                    color='primary'
                                    variant='text'
                                    size='small'
                                    component='a'
                                    href='/login'
                                    target='_blank'
                                >
                                    Sign in
                                </Button>
                                <Button
                                    color='primary'
                                    variant='contained'
                                    size='small'
                                    component='a'
                                    href='/register'
                                    target='_blank'
                                >
                                    Sign up
                                </Button>
                            </>
                            }
                        </Box>
                        <Box sx={{ display: { sm: '', md: 'none' } }}>
                            <Button
                                color='primary'
                                variant='text'
                                aria-label='menu'
                                onClick={toggleDrawer(true)}
                                sx={{ minWidth: '30px', p: '4px' }}
                            >
                                <MenuIcon />
                            </Button>
                            <Drawer anchor='right' open={open} onClose={toggleDrawer(false)}>
                                <Box
                                    sx={{
                                        minWidth: '60dvw',
                                        p: 2,
                                        backgroundColor: 'background.paper',
                                        flexGrow: 1,
                                    }}
                                >
                                    <MenuItem onClick={() => {navigate('/explore'); toggleDrawer(false);}}>
                                        Explore
                                    </MenuItem>
                                    <MenuItem onClick={() => {navigate('/library'); toggleDrawer(false);}}>
                                        Library
                                    </MenuItem>
                                    <MenuItem onClick={() => {navigate('/decks'); toggleDrawer(false);}}>
                                        Decks
                                    </MenuItem>
                                    <Divider />
                                    {userObject ?
                                        <MenuItem onClick={() => {navigate('/account'); toggleDrawer(false);}}>
                                            <FontAwesomeIcon icon='fa-regular fa-circle-user' />
                                            {userObject.username}
                                        </MenuItem>
                                    :
                                    <>
                                        <MenuItem>
                                            <Button
                                                color='primary'
                                                variant='contained'
                                                component='a'
                                                href='/register'
                                                target='_blank'
                                                sx={{ width: '100%' }}
                                            >
                                                Sign up
                                            </Button>
                                        </MenuItem>
                                        <MenuItem>
                                            <Button
                                                color='primary'
                                                variant='outlined'
                                                component='a'
                                                href='/login'
                                                target='_blank'
                                                sx={{ width: '100%' }}
                                            >
                                                Sign in
                                            </Button>
                                        </MenuItem>
                                    </>
                                    }
                                </Box>
                            </Drawer>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <Toolbar />
        </div>
        </>
    )
}

export default Navbar;