import React, { useState, useEffect } from 'react';
import { TextField, Grid, Button, Paper, Container, Fab } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAuthContext } from '../contexts/AuthContext';
import { useFilterContext } from '../contexts/FilterContext';
import TuneIcon from '@mui/icons-material/Tune';
import { blue, grey, red } from '@mui/material/colors';
import './CardFilter.css';

export default function CardFilter(properties) {
    const { cards } = properties;
    const { setFilteredCards } = properties;
    const { icons } = useAuthContext();
    const [loading, setLoading] = useState(true);
    const [inkFilters, setInkFilters] = useState([]);
    const [rarityFilters, setRarityFilters] = useState([]);
    const [chapterFilters, setChapterFilters] = useState([]);
    const [quantityFilters, setQuantityFilters] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [isActive, setActive] = useState(false);

    const theme = createTheme({
        palette: {
            primary: blue,
            background: {
                default: grey[50],
                paper: red,
            }
        }
    });

    const fabStyle = {
        position: 'absolute',
        bottom: 16,
        left: 16,
    };

    const toggleClass = () => {
        setActive(!isActive);
    }

    const filterCards = () => {
        const cardsFiltered = cards.filter(card => {
            return (
                (!inkFilters.length || inkFilters.includes(card.ink)) &&
                (!rarityFilters.length || rarityFilters.includes(card.rarity)) &&
                (!chapterFilters.length || chapterFilters.includes(card.chapter)) &&
                (!quantityFilters.length || ((Math.min(...quantityFilters) === 0) && (card.library.standard + card.library.foil) === 0)) &&
                (!quantityFilters.length || ((Math.min(...quantityFilters) != 0) && (card.library.standard + card.library.foil) >= Math.min(...quantityFilters))) &&
                (!searchInput.length || card.name.toLowerCase().includes(searchInput) || card.subname.toLowerCase().includes(searchInput))
            );
        });
        setFilteredCards(cardsFiltered);
    };

    const filterCardsQuantity = () => {
        let cardsFiltered = [];
        if (quantityFilters.length > 0) {
            if (Math.min(...quantityFilters) === 0) {
                cardsFiltered = cards.filter(card => {
                    return ((card.library.standard + card.library.foil) === 0);
                });
            } else {
                cardsFiltered = cards.filter(card => {
                    return (card.library.standard + card.library.foil) >= Math.min(...quantityFilters);
                });
            }
        } else {
            cardsFiltered = cards;
        }
        setFilteredCards(cardsFiltered);
    }

    const handleFilter = (filter, selection) => {
        let filters = [];

        if (filter === 'ink') {
            filters = inkFilters;
        } else if (filter === 'rarity') {
            filters = rarityFilters;
        } else if (filter === 'chapter') {
            filters = chapterFilters;
        }

        if (filters.includes(selection)) {
            const index = filters.indexOf(selection);
            filters.splice(index, 1);
        } else {
            filters.push(selection);
        }
        
        if (filter === 'ink') {
            setInkFilters(filters);
        } else if (filter === 'rarity') {
            setRarityFilters(filters);
        } else if (filter === 'chapter') {
            setChapterFilters(filters);
        }

        filterCards();
    };

    const handleFilterQuantity = (selection) => {
        if (quantityFilters.includes(selection)) {
            const index = quantityFilters.indexOf(selection);
            quantityFilters.splice(index, 1);
        } else {
            quantityFilters.push(selection);
        }
        setQuantityFilters(quantityFilters);
        filterCards();
    }

    const handleSearch = (event) => {
        event.preventDefault();

        setSearchInput(event.target.value.toLowerCase());
        filterCards();
    };

    useEffect(() => {
        setLoading(false);
    }, []);

    //type filtering
    //sorting options

    return (
        <>
            <ThemeProvider theme={theme}>
                <Container>
                    <Paper elevation={3} sx={{ backgroundColor: '#3b3b3b'}}>
                        {!loading ? 
                        <>
                            <Grid item xs={12}>
                                <TextField fullWidth label='search' value={searchInput} onChange={handleSearch} />
                                <Button className={isActive ? 'active' : null} onClick={() => {handleFilter('chapter', 1); toggleClass();}}>
                                    <img className='setIcon' src={icons.setOneWhite} alt='The First Chapter' />
                                </Button>
                                <Button onClick={() => handleFilter('chapter', 2)}>
                                    <img className='setIcon' src={icons.setTwo} alt='Rise of the Floodborn' />
                                </Button>
                                <Button onClick={() => handleFilter('chapter', 3)}>
                                    <img className='setIcon' src={icons.setThree} alt='Into the Inklands' />
                                </Button>
                                <Button onClick={() => handleFilter('chapter', 4)}>
                                    <img className='setIcon' src={icons.setFour} alt='Ursulas Return' />
                                </Button>
                                <Button onClick={() => handleFilter('chapter', 5)}>
                                    <img className='setIcon' alt='Shimmering Skies' />
                                </Button>
                                |
                                <Button onClick={() => handleFilterQuantity(1)}>Master Set</Button>
                                <Button onClick={() => handleFilterQuantity(4)}>Play Set</Button>
                                <Button onClick={() => handleFilterQuantity(0)}>Incomplete</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button className='rounded-md' onClick={() => handleFilter('ink', 'amber')}>
                                    <img className='filterIcon' src={icons.amber} alt='amber' />
                                </Button>
                                <Button className='rounded-md' onClick={() => handleFilter('ink', 'amethyst')}>
                                    <img className='filterIcon' src={icons.amethyst} alt='amethyst' />
                                </Button>
                                <Button className='rounded-md' onClick={() => handleFilter('ink', 'emerald')}>
                                    <img className='filterIcon' src={icons.emerald} alt='emerald' />
                                </Button>
                                <Button className='rounded-md' onClick={() => handleFilter('ink', 'ruby')}>
                                    <img className='filterIcon' src={icons.ruby} alt='ruby' />
                                </Button>
                                <Button className='rounded-md' onClick={() => handleFilter('ink', 'sapphire')}>
                                    <img className='filterIcon' src={icons.sapphire} alt='sapphire' />
                                </Button>
                                <Button className='rounded-md' onClick={() => handleFilter('ink', 'steel')}>
                                    <img className='filterIcon' src={icons.steel} alt='steel' />
                                </Button>
                                |
                                <Button className='rounded-md' onClick={() => handleFilter('rarity', 'common')}>
                                    <img className='filterIcon' src={icons.common} alt='common' />
                                </Button>
                                <Button className='rounded-md' onClick={() => handleFilter('rarity', 'uncommon')}>
                                    <img className='filterIcon' src={icons.uncommon} alt='uncommon' />
                                </Button>
                                <Button className='rounded-md' onClick={() => handleFilter('rarity', 'rare')}>
                                    <img className='filterIcon' src={icons.rare} alt='rare' />
                                </Button>
                                <Button className='rounded-md' onClick={() => handleFilter('rarity', 'superRare')}>
                                    <img className='filterIcon' src={icons.superRare} alt='superRare' />
                                </Button>
                                <Button className='rounded-md' onClick={() => handleFilter('rarity', 'legendary')}>
                                    <img className='filterIcon' src={icons.legendary} alt='legendary' />
                                </Button>
                                <Button className='rounded-md' onClick={() => handleFilter('rarity', 'enchanted')}>
                                    <img className='filterIcon' src={icons.enchanted} alt='enchanted' />
                                </Button>
                                <Button className='rounded-md' onClick={() => handleFilter('rarity', 'promo')}>
                                    <img className='filterIcon' src={icons.promo} alt='promo' />
                                </Button>
                            </Grid>
                        </>
                        : <p>Loading filters...</p>}
                    </Paper>
                </Container>
            </ThemeProvider>
        </>
    )
}