import './App.css';
import { Routes, Route } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { Navbar, Register, Login, PrivateRoute, AdminRoute, ResetPassword, LorcanaCard } from './components'
import { Home, Library, Decks, Account, Explore } from './components/pages'
import { AuthProvider } from './contexts/AuthContext';
import { CardProvider } from './contexts/CardContext';
import { FilterProvider } from './contexts/FilterContext';

function App() {
  return (
    <>
      <AuthProvider>
        <CardProvider>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/explore" element={<Explore />} />
            <Route path="/library" element={<PrivateRoute><Library /></PrivateRoute>} />
            <Route path="/decks" element={<PrivateRoute><Decks /></PrivateRoute>} />
            <Route path="/account" element={<PrivateRoute><Account /></PrivateRoute>} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/passwordreset" element={<ResetPassword />} />
            <Route path="/lorcana/:id" element={<LorcanaCard />} />
          </Routes>
        </CardProvider>
      </AuthProvider>
    </>
  );
}

export default App;
library.add(far);
library.add(fas);