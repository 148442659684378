import React, { useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import { useAuthContext } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { convertLibrary } from '../../services/cards';

export default function Home() {
    const { currentUser } = useAuthContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (currentUser != null) {
            navigate('/');
        }

    }, []);
    

    return (
        <>
            <Container className='d-flex mt-5 pt-5 justify-content-center'>
                <div className='col-6 text-center'>
                    <h1>Welcome to Traders Trove!</h1>
                    <p>
                        This site is your one stop shop for managing your Disney's Lorcana trading card collection. 
                        You can use this site to explore each set, keep track of your collection and create and manage 
                        your decks.
                    </p>
                    <p>
                        In the future, I plan to add more functions to enhance the social aspect of collecting, increase the
                        efficiency of entering new cards into your collection and potentially expand to include other card games 
                        so you can use one system for all of your collections. Stay tuned!
                    </p>
                    <Button variant='primary' size='lg' href='/register'>Get started!</Button>
                    <Button variant='link' href='https://www.disneylorcana.com/en-US/how-to-play'>Learn how to play!</Button>
                </div>
            </Container>
        </>
    )
}