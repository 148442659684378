import React, { useState, useEffect, useRef } from 'react';
import { Grid, Typography, Stack } from '@mui/material';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import { useAuthContext } from '../../contexts/AuthContext';
import { CardItem, CardFilter } from '../';
import './Explore.css';

export default function Explore() {
    const [loading, setLoading] = useState(true);
    const [filteredCards, setFilteredCards] = useState();
    const [maxIndex, setMaxIndex] = useState(30);
    const { cards } = useAuthContext();
    const bottomRef = useRef(null);
    const clickedCardRef = useRef(null);

    useEffect(() => {
        setFilteredCards(cards);
        setLoading(false);

        const storedMaxIndex = sessionStorage.getItem('exploreMaxIndex');

        if (storedMaxIndex) {
            setMaxIndex(parseInt(storedMaxIndex));
        }

        const clickedCard = sessionStorage.getItem('clickedCard');

        if (clickedCard) {
            console.log('scrollposition: ' + clickedCard)
            sessionStorage.removeItem('clickedCard');
        }

        const insertNewCards = () => {
            if (maxIndex < cards.length + 1) {
                const newIndex = maxIndex + 30;
                setMaxIndex(newIndex);
                sessionStorage.setItem('exploreMaxIndex', newIndex);
            }
        }

        const cardsObserver = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.intersectionRatio > 0 && entry.intersectionRatio < 1) {
                    insertNewCards();
                }
            });
        }, {
            root: null,
            rootMargin: '0px',
            threshold: 0
        });

        cardsObserver.observe(bottomRef.current);
        return () => {
            cardsObserver.disconnect(bottomRef.current);
        }
    }, [maxIndex]);

    return (
        <>
            {!loading ? 
            <Grid container justifyContent='center' className='bigMargin'>
                <Grid item xs={12}>
                    <Typography variant='h3' className='text-center'>Explore All Cards</Typography>
                </Grid>
                <CardFilter cards={cards} setFilteredCards={setFilteredCards} />
                <Grid container justifyContent='center' xs={11} lg={10} xl={8}>
                    {filteredCards.filter((card, index) => index < maxIndex).map(card => <CardItem key={card.id} card={card} />)}
                </Grid>
            </Grid>
            : null }
            <div ref={bottomRef}>End of Cards</div>
        </>
    )
}