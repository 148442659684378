import { auth, db } from "../firebase";
import { setDoc, collection } from 'firebase/firestore';
import { createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword, updatePassword } from "firebase/auth";

export const register = async (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
}

export const login = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
}

export const logout = () => {
    return auth.signOut();
}

export const passwordReset = (email) => {
    return sendPasswordResetEmail(auth, email);
}

export const changePassword = (password) => {
    return updatePassword(auth.currentUser, password);
}

export const sendEmailVerification = () => {
    return sendEmailVerification(auth.currentUser, { url: '${window.location.origin}/home',});
}