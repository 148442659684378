import React, { useState } from 'react';
import { Card, Button, Alert, Container } from 'react-bootstrap';
import { useAuthContext } from '../../contexts/AuthContext';
import { Library, updateLibrary } from '../../services/cards';
import Papa from 'papaparse';
import { logout } from '../auth';
import { useNavigate } from 'react-router-dom';

export default function Account() {
    const [error, setError] = useState('');
    const { currentUser } = useAuthContext();
    const navigate = useNavigate();
    const library = [];

    async function handleLogout() {
        setError('');

        try {
            await logout();
            navigate('/login');
        } catch {
            setError('Failed to log out')
        }
    }

    const handleImport = (e) => {
        e.preventDefault();

        const file = e.target.files[0];
        const records = [];

        Papa.parse(file, {
            header: true,
            dynamicTyping: true,
            transformHeader: (h) => {
                return h.replace(/\s/g, '');
            },
            complete: (results) => {
                const importFile = (results.data);
                importFile.map(record => {
                    records.push(record);
                })
                records.forEach(card => {
                    let index;
                    let chapter;
                    let puppyCount = 0;
                    if (card.Normal !== null) {
                        if (typeof card.Set === 'string' && card.Set.includes('P')){
                            let number = parseInt(card.CardNumber.replace('P', ''));
                            if (number < 10) {
                                index = '00' + number;
                            } else if (number < 100) {
                                index = '0' + number;
                            } else {
                                index = number
                            }
                            chapter = '99';
                        } else if (card.Set === 3 && card.CardNumber === 4) {
                            if (puppyCount === 0) {
                                index = '0041';
                                ++puppyCount;
                            } else if (puppyCount === 1) {
                                index = '0042';
                                ++puppyCount;
                            } else if (puppyCount === 2) {
                                index = '0043';
                                ++puppyCount;
                            } else if (puppyCount === 3) {
                                index = '0044';
                                ++puppyCount;
                            } else if (puppyCount === 4) {
                                index = '0045';
                                ++puppyCount;
                            }                            
                            chapter = '03';
                        } else {
                            if (card.CardNumber < 10) {
                                index = '00' + card.CardNumber;
                                chapter = '0' + card.Set;
                            } else if (card.CardNumber < 100) {
                                index = '0' + card.CardNumber;
                                chapter = '0' + card.Set;
                            } else {
                                index = card.CardNumber;
                                chapter = '0' + card.Set;
                            }
                        }
            
                        let newId = chapter + index;

                        const newLibrary = new Library(newId, card.Normal, card.Foil);
                        library.push(newLibrary);
                    }
                })
                updateLibrary(JSON.stringify(library), currentUser.uid.toString());
            },
        });
    }

    return (
        <>
            <Container className='d-flex align-items-center justify-content-center' style={{ minHeight: '100vh' }}>
                <div className='w-100' style={{ maxWidth: '400px' }}>
                    <Card>
                        <Card.Body>
                            <h2 className='text-center mb-4'>Profile</h2>
                            {error && <Alert variant='danger'>{error}</Alert>}
                            <strong>Email: </strong>{currentUser.email}
                            <label htmlFor='libImport'>Import Library from Dreamborn (Dalmatian Puppies won't transfer, you'll need to add those manually)</label>
                            <input type='file' accept='.csv' onInput={handleImport} onClick={(e) => {e.target.value = null}} id='libImport' />
                        </Card.Body>
                    </Card>
                    <div className='w-100 text-center mt-2'>
                        <Button variant='link' onClick={handleLogout}>Log Out</Button>
                    </div>
                </div>
            </Container>
        </>
    )
}