import React, { useContext, useState, useEffect } from 'react';
import { fetchCards } from '../services/cards';
import { useAuthContext } from '../contexts/AuthContext';

const CardContext = React.createContext();

export function useCardContext() {
    return useContext(CardContext);
}

export function CardProvider({ children }) {
    const [loading, setLoading] = useState(true);
    const [cards, setCards] = useState([]);
    const { currentUser } = useAuthContext();

    useEffect(() => {
        const requestCards = async () => {
            const response = await fetchCards(currentUser ? currentUser.uid.toString() : 'fKhUkmrmctX8fWicpnxHoK6DWaa2');
            setCards(response);
            setLoading(false);
        }

        requestCards();
    }, []);

    const value = {
        cards
    }

    return (
        <CardContext.Provider value={value}>
            {!loading && children}
        </CardContext.Provider>
    )
}