import React from 'react';
import { Grid, Typography } from '@mui/material'

export default function Decks() {
    return (
        <>
            <Grid container spacing={0} alignItems='center'>
                <Grid item xs={12}>
                    <Typography align='center'>Decks</Typography>
                    <Typography align='center'>Coming Soon</Typography>
                </Grid>
            </Grid>
        </>
    )
}