import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import { ref, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../firebase';

const card_doc = doc(db, 'cards-lorcana', 'card-data');
let cards = [];
let library = [];

function setCards(data) {
    cards = data;
}

function setLibrary(data) {
    library = data;
}

export const lpad = (value, padding) => {
    let zeroes = new Array(padding+1).join('0');
    return (zeroes + value).slice(-padding);
}

export const fetchImages = async (imgFile) => {
    const imgRef = ref(storage, imgFile);
    const imgUrl = await getDownloadURL(imgRef);
    return imgUrl;
}

class Icons {
    constructor(amber, amethyst, emerald, ruby, sapphire, steel, common, uncommon, rare, superRare, legendary,
        enchanted, promo, setOneWhite, setOneBlack, setTwo, setThree, setFour) {
        this.amber = amber;
        this.amethyst = amethyst;
        this.emerald = emerald;
        this.ruby = ruby;
        this.sapphire = sapphire;
        this.steel = steel;
        this.common = common;
        this.uncommon = uncommon;
        this.rare = rare;
        this.superRare = superRare;
        this.legendary = legendary;
        this.enchanted = enchanted;
        this.promo = promo;
        this.setOneWhite = setOneWhite;
        this.setOneBlack = setOneBlack;
        this.setTwo = setTwo;
        this.setThree = setThree;
        this.setFour = setFour;
    }
}

export class Library {
    constructor(id, standard, foil) {
        this.id = id;
        this.standard = standard;
        this.foil = foil;
    }

    addStandard() {
        this.standard++;
    }

    removeStandard() {
        this.standard--;
    }

    addFoil() {
        this.foil++;
    }

    removeFoil() {
        this.foil--;
    }
}

class Card {
    constructor(id, chapter, index, cost, name, subname, rarity, ink, inkable, type, strength, willpower, 
        lore, movecost, library = null) {

        this.id = id;
        this.chapter = chapter;
        this.index = index;
        this.cost = cost;
        this.name = name;
        this.subname = subname;
        this.rarity = rarity;
        this.ink = ink;
        this.inkable = inkable;
        this.type = type;
        this.strength = strength;
        this.willpower = willpower;
        this.lore = lore;
        this.movecost = movecost;
        this.imgUrl = '';
        this.library = library;
    }

    setUrl(url) {
        this.imgUrl = url;
    }

    setLibrary(library) {
        this.library = library;
    }

    update(id, chapter, index, cost, name, subname, rarity, ink, inkable, type, strength, willpower, lore, movecost) {
        this.id = id;
        this.chapter = chapter;
        this.index = index;
        this.cost = cost;
        this.name = name;
        this.subname = subname;
        this.rarity = rarity;
        this.ink = ink;
        this.inkable = inkable;
        this.type = type;
        this.strength = strength;
        this.willpower = willpower;
        this.lore = lore;
        this.movecost = movecost;

        let dbCard = (({id, imgUrl, library, ...rest}) => rest)(this);
        updateDoc(card_doc, {
            [this.id] : JSON.stringify(dbCard)
        });
    }
}

export const fetchCards = async (uid) => {
    const cardSnap = await getDoc(card_doc);

    const cardFileResult = [];
    cardFileResult.push(cardSnap.data());
    const cardFileData = cardFileResult[0]

    if (uid) {
        const librarySnap = await getDoc(doc(db, 'card-libraries', uid));
        if (librarySnap.exists()) {
            const data = librarySnap.data();
            setLibrary(JSON.parse(data.lorcana));
        } else {
            const library = [];

            Object.keys(cardFileData).map(key => {
               const newRecord = new Library(key, 0, 0);
                library.push(newRecord);
            });
            
            const jsonLibrary = JSON.stringify(library)

            setDoc(doc(db, 'card-libraries', uid), {
                'lorcana' : jsonLibrary
            });

            setLibrary(library);
        }
    }

    setCards(Object.keys(cardFileData).map(key => {
        const data = JSON.parse(cardFileData[key]);
        
        let libraryRecord;
        let libraryObject;

        if (library.find(c => c.id === key))  {
            libraryRecord = library.find(c => c.id === key);
            libraryObject = new Library(key, libraryRecord.standard, libraryRecord.foil)
        } else {
            libraryObject = new Library(key, 0, 0)
        }
        

        const card = new Card(key, data.chapter, data.index, data.cost, data.name, data.subname, data.rarity, data.ink, data.inkable, data.type, data.strength, data.willpower, data.lore, data.movecost, libraryObject);
        return card;
    }));

    cards.forEach((card) => {
        try {
            fetchImages('lorcana-card-images/' + card.id + '.webp').then((url) => {
                card.setUrl(url);
            });
        } catch {
            fetchImages('lorcana-card-images/cardback.webp').then((url) => {
                card.setUrl(url);
            });
        }
    });

    return cards.sort((a, b) => a.id.localeCompare(b.id));
}

export const updateLibrary = async (library, uid) => {
    updateDoc(doc(db, 'card-libraries', uid), {
        'lorcana' : library
    });
}

export const fetchIcons = async () => {
    const amberImgUrl = await fetchImages('lorcana-icons/amber.svg');
    const amethystImgUrl = await fetchImages('lorcana-icons/amethyst.svg');
    const emeraldImgUrl = await fetchImages('lorcana-icons/emerald.svg');
    const rubyImgUrl = await fetchImages('lorcana-icons/ruby.svg');
    const sapphireImgUrl = await fetchImages('lorcana-icons/sapphire.svg');
    const steelImgUrl = await fetchImages('lorcana-icons/steel.svg');
    const commonImgUrl = await fetchImages('lorcana-icons/common.svg');
    const uncommonImgUrl = await fetchImages('lorcana-icons/uncommon.svg');
    const rareImgUrl = await fetchImages('lorcana-icons/rare.svg');
    const superRareImgUrl = await fetchImages('lorcana-icons/super_rare.svg');
    const legendaryImgUrl = await fetchImages('lorcana-icons/legendary.svg');
    const enchantedImgUrl = await fetchImages('lorcana-icons/enchanted.png');
    const promoImgUrl = await fetchImages('lorcana-icons/promo.webp');
    const setOneWhiteImgUrl = await fetchImages('lorcana-icons/setOneWhite.png');
    const setOneBlackImgUrl = await fetchImages('lorcana-icons/setOneBlack.png');
    const setTwoImgUrl = await fetchImages('lorcana-icons/setTwo.png');
    const setThreeImgUrl = await fetchImages('lorcana-icons/setThree.png');
    const setFourImgUrl = await fetchImages('lorcana-icons/setFour.png');

    return new Icons(amberImgUrl, amethystImgUrl, emeraldImgUrl, rubyImgUrl, sapphireImgUrl, steelImgUrl, 
        commonImgUrl, uncommonImgUrl, rareImgUrl, superRareImgUrl, legendaryImgUrl, enchantedImgUrl, 
        promoImgUrl, setOneWhiteImgUrl, setOneBlackImgUrl, setTwoImgUrl, setThreeImgUrl, setFourImgUrl);
}

export const addNewChapter = async () => {
    let numOfCardsInSet = 223;

    console.log('function entered')
    for (let i = 0; i < numOfCardsInSet + 1; i++) {
        var id = '05' + lpad(i, 3);
        if (i < 35) {
            let card = new Card(id, 5, i, 0, '', '', '', 'amber', false, '', 0, 0, 0, 0)
            card.update(card.id, card.chapter, card.index, card.cost, card.name, card.subname, card.rarity, card.ink, card.inkable, card.type, card.strength, card.willpower, card.lore, card.movecost);
        } else if (i < 69) {
            let card = new Card(id, 5, i, 0, '', '', '', 'amethyst', false, '', 0, 0, 0, 0)
            card.update(card.id, card.chapter, card.index, card.cost, card.name, card.subname, card.rarity, card.ink, card.inkable, card.type, card.strength, card.willpower, card.lore, card.movecost);
        } else if (i < 103) {
            let card = new Card(id, 5, i, 0, '', '', '', 'emerald', false, '', 0, 0, 0, 0)
            card.update(card.id, card.chapter, card.index, card.cost, card.name, card.subname, card.rarity, card.ink, card.inkable, card.type, card.strength, card.willpower, card.lore, card.movecost);
        } else if (i < 137) {
            let card = new Card(id, 5, i, 0, '', '', '', 'ruby', false, '', 0, 0, 0, 0)
            card.update(card.id, card.chapter, card.index, card.cost, card.name, card.subname, card.rarity, card.ink, card.inkable, card.type, card.strength, card.willpower, card.lore, card.movecost);
        } else if (i < 171) {
            let card = new Card(id, 5, i, 0, '', '', '', 'sapphire', false, '', 0, 0, 0, 0)
            card.update(card.id, card.chapter, card.index, card.cost, card.name, card.subname, card.rarity, card.ink, card.inkable, card.type, card.strength, card.willpower, card.lore, card.movecost);
        } else if (i < 205) {
            let card = new Card(id, 5, i, 0, '', '', '', 'steel', false, '', 0, 0, 0, 0)
            card.update(card.id, card.chapter, card.index, card.cost, card.name, card.subname, card.rarity, card.ink, card.inkable, card.type, card.strength, card.willpower, card.lore, card.movecost);
        } else if (i < 208) {
            let card = new Card(id, 5, i, 0, '', '', 'enchanted', 'amber', false, '', 0, 0, 0, 0)
            card.update(card.id, card.chapter, card.index, card.cost, card.name, card.subname, card.rarity, card.ink, card.inkable, card.type, card.strength, card.willpower, card.lore, card.movecost);
        } else if (i < 211) {
            let card = new Card(id, 5, i, 0, '', '', 'enchanted', 'amethyst', false, '', 0, 0, 0, 0)
            card.update(card.id, card.chapter, card.index, card.cost, card.name, card.subname, card.rarity, card.ink, card.inkable, card.type, card.strength, card.willpower, card.lore, card.movecost);
        } else if (i < 214) {
            let card = new Card(id, 5, i, 0, '', '', 'enchanted', 'emerald', false, '', 0, 0, 0, 0)
            card.update(card.id, card.chapter, card.index, card.cost, card.name, card.subname, card.rarity, card.ink, card.inkable, card.type, card.strength, card.willpower, card.lore, card.movecost);
        } else if (i < 217) {
            let card = new Card(id, 5, i, 0, '', '', 'enchanted', 'ruby', false, '', 0, 0, 0, 0)
            card.update(card.id, card.chapter, card.index, card.cost, card.name, card.subname, card.rarity, card.ink, card.inkable, card.type, card.strength, card.willpower, card.lore, card.movecost);
        } else if (i < 220) {
            let card = new Card(id, 5, i, 0, '', '', 'enchanted', 'sapphire', false, '', 0, 0, 0, 0)
            card.update(card.id, card.chapter, card.index, card.cost, card.name, card.subname, card.rarity, card.ink, card.inkable, card.type, card.strength, card.willpower, card.lore, card.movecost);
        } else if (i < 223) {
            let card = new Card(id, 5, i, 0, '', '', 'enchanted', 'steel', false, '', 0, 0, 0, 0)
            card.update(card.id, card.chapter, card.index, card.cost, card.name, card.subname, card.rarity, card.ink, card.inkable, card.type, card.strength, card.willpower, card.lore, card.movecost);
        } else {
            let card = new Card(id, 5, i, 0, '', '', '', 'amethyst', false, '', 0, 0, 0, 0)
            card.update(card.id, card.chapter, card.index, card.cost, card.name, card.subname, card.rarity, card.ink, card.inkable, card.type, card.strength, card.willpower, card.lore, card.movecost);
        }
    }
}